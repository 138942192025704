// firebaseConfig.ts
export const firebaseConfig = {
    apiKey: "AIzaSyD6SKPnV9PCiHCQa-3bweqvk_XkYFvZtdg",
    authDomain: "personal-memory-assistant.firebaseapp.com",
    projectId: "personal-memory-assistant",
    storageBucket: "personal-memory-assistant.appspot.com",
    messagingSenderId: "24593728640",
    appId: "1:24593728640:web:730685a44fd8d090e2d265",
    measurementId: "G-HWLWVESDCM"
  };
