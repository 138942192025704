// Task List as a Beautiful Table
import './App.css';
import { fetchTasks, markTaskDone, markTaskDoneInFuture } from './taskService';
import React, { useState, useEffect } from 'react';

const TaskList = () => {
  const [tasks, setTasks] = useState([]);

  useEffect(() => {
    const getTasks = async () => {
      const fetchedTasks = await fetchTasks();
      setTasks(fetchedTasks);
    };

    getTasks();
  }, []);

  const handleMarkDone = async (taskId) => {
    await markTaskDone(taskId);
  };

  const handleMarkDoneInFuture = async (taskId) => {
    // Implement the logic for marking the task as done in the future
    console.log(`Task ${taskId} will be marked as done in the future.`);
    
    await markTaskDoneInFuture(taskId);
  };

  return (
    <div>
      <h1>Task List</h1>
      <table style={{border: '1px solid black', borderCollapse: 'collapse', width: '100%'}}> 
        <thead>
          <tr>
            <th style={{border: '1px solid black'}}>Last Done</th>
            <th style={{border: '1px solid black'}}>Platform</th>
            <th style={{border: '1px solid black'}}>Show Time</th>
            <th style={{border: '1px solid black'}}>Mark as Done</th>
            <th style={{border: '1px solid black'}}>Mark as Done in Future</th> {/* Added another action */}
            <th style={{border: '1px solid black'}}>Task</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map(task => (
            <tr key={task.id}>
              <td style={{border: '1px solid black'}}>{task.lastDone}</td>
              <td style={{border: '1px solid black'}}>{task.platform}</td>
              <td style={{border: '1px solid black'}}>{task.showTime}</td>
              <td style={{border: '1px solid black'}}><button onClick={() => handleMarkDone(task.id)}>Mark as Done</button></td>
              <td style={{border: '1px solid black'}}><button onClick={() => handleMarkDoneInFuture(task.id)}>Mark as Done in Future</button></td> {/* Added button for new action */}
              <td style={{border: '1px solid black'}}>{task.data}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <a href="https://console.cloud.google.com/firestore/databases/-default-/data/panel/new_tasks/first_task?authuser=0&hl=en&project=personal-memory-assistant"> Studio</a> Tip: only_once
    </div>
  );
};

function App() {
  return (
    <div className="App">
      <TaskList />
    </div>
  );
}

export default App;
