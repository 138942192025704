// Debugging console.log statements added to the fetchTasks function
// and markTaskDone function to print every step of the logical process

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, updateDoc, doc, serverTimestamp, Timestamp } from 'firebase/firestore';
import { firebaseConfig } from './firebaseConfig';

const TASKS_COLLECTION = 'new_tasks'; // new_tasks

export const fetchTasks = async () => {
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  try {
    const querySnapshot = await getDocs(collection(db, TASKS_COLLECTION));
    const tasksList = [];
    const now = new Date();
    
    querySnapshot.forEach((doc) => {
      const taskData = doc.data();
      const showTime = taskData["show_time"] ? taskData["show_time"].toDate() : null;
      const lastDone = taskData["last_done"] ? taskData["last_done"].toDate() : null;
      
      if (showTime) {
        const showTimeToday = new Date(now.getFullYear(), now.getMonth(), now.getDate(), showTime.getHours(), showTime.getMinutes(), showTime.getSeconds());
        const lastDoneDay = lastDone ? new Date(lastDone.getFullYear(), lastDone.getMonth(), lastDone.getDate(), lastDone.getHours(), lastDone.getMinutes()) : null;
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        
        console.log('showTimeToday:', showTimeToday);
        console.log('lastDoneDay:', lastDoneDay);
        console.log('today:', today);
        
        tasksList.push({ 
          id: doc.id, 
          data: taskData["DO"],
  
          lastDone: lastDoneDay ? `${lastDoneDay.toLocaleDateString()} ${lastDoneDay.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}` : null,
          
          showTime: showTimeToday.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
          
          platform: taskData["platform"] || 'unknown', // Added platform field
        });
        
      }
    });
    return tasksList;
  } catch (error) {
    console.error("Error fetching tasks: ", error);
    return [];
  }
};

// Function to get the current timestamp or a provided date
const getCurrentTimestamp = (date = null) => {
  return date ? date : serverTimestamp();
};

// Mark task as done with optional last_done parameter
export const markTaskDone = async (taskId, lastDone = null) => {
  const platform = typeof window !== 'undefined' ? navigator.platform : 'unknown';
  console.log(platform); // Debugging console.log statement to check platform value
  try {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const taskRef = doc(db, TASKS_COLLECTION, taskId);
    
    const lastDoneTimestamp = getCurrentTimestamp(lastDone);
    console.log('lastDoneTimestamp:', lastDoneTimestamp); // Debugging console.log statement to check lastDoneTimestamp value
  
    await updateDoc(taskRef, { last_done: lastDoneTimestamp, 'platform': platform });
    return true;
  } catch (error) {
    console.error("Error marking task as done: ", error);
    return false;
  }
};

// Function to mark task as done with a future date based on days parameter
export const markTaskDoneInFuture = async (taskId, days = 1) => {
  const platform = typeof window !== 'undefined' ? navigator.platform : 'unknown';
  console.log(platform); // Debugging console.log statement to check platform value
  try {
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const taskRef = doc(db, TASKS_COLLECTION, taskId);
    
    const futureDate = new Date();
    futureDate.setDate(futureDate.getDate() + days);
    const futureTimestamp = Timestamp.fromDate(futureDate);
    console.log('futureTimestamp:', futureTimestamp); // Debugging console.log statement to check futureTimestamp value
  
    await updateDoc(taskRef, { last_done: futureTimestamp, 'platform': platform });
    return true;
  } catch (error) {
    console.error("Error marking task as done in future: ", error);
    return false;
  }
};
